import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const Reveal = ({ children, threshold = 0.5 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;  // Copy ref.current to a variable inside the effect
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        // Only update to 'true' and only once when the element first intersects
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
          observer.disconnect(); // Disconnect observer after triggering the first time
        }
      },
      {
        threshold: threshold,  // Use the passed threshold value
      }
    );

    if (element) {
      observer.observe(element);
    }

    // Clean up observer on component unmount
    return () => {
      if (element) {
        observer.disconnect();
      }
    };
  }, [isVisible, threshold]); // Adding threshold as a dependency ensures the effect runs if threshold changes

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isVisible ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }} // Customize the animation duration as needed
    >
      {children}
    </motion.div>
  );
};

export default Reveal;
