import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./screens/HomePage.js"; // Ensure this path is correct

import { initializeApp } from "firebase/app";
// Optionally, add the Firebase services that you want to use
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBUN1HE_V_o7kI3jKfO1riWAo0yxVkjh-s",
  authDomain: "grimeswasright.firebaseapp.com",
  projectId: "grimeswasright",
  storageBucket: "grimeswasright.appspot.com",
  messagingSenderId: "48462962979",
  appId: "1:48462962979:web:0f8cd27ab0d5455402ac96",
  measurementId: "G-VT1LV2EKNZ",
};

const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const storage = getStorage(app);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
