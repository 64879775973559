import React, { useState, useEffect } from "react";
import "../stylesheets/Section3.css";
import robot1 from "../assets/images/robot1.png";
import robot2 from "../assets/images/robot2.png";
import dadImage from "../assets/images/Takedadsjob.png";

import LongSwr from "../assets/images/LongSwr.png";
import SolutionPic from "../assets/images/unemploymentisthesolution.png";

const Section3 = () => {
  const [currentImage, setCurrentImage] = useState(robot1); // eslint-disable-line no-unused-vars
  const [isElectrocuted, setIsElectrocuted] = useState(false); // eslint-disable-line no-unused-vars

  useEffect(() => {
    // Function to initiate the switching and animation
    const initiateSwitch = () => {
      setIsElectrocuted(true); // Begin with robot2's animation
      setCurrentImage(robot2); // Switch to robot2 for the animation

      // After the animation duration, switch back to robot1
      setTimeout(() => {
        setIsElectrocuted(false); // End the animation
        setCurrentImage(robot1); // Switch back to robot1

        // After switching back to robot1, set a timeout to restart the sequence
        // This includes the static period of robot1 + any desired delay before restarting the cycle
      }, 800); // Animation duration of robot2
    };

    // Start the switch after an initial delay

    // Repeat the sequence at intervals including the initial 2s delay, the 600ms animation, and any static period you wish for robot1
    const intervalDuration = 1500; // Example: 4000ms total before repeating
    const repeatSequence = setInterval(initiateSwitch, intervalDuration);

    // Cleanup timeouts and intervals when the component unmounts or the effect reruns
    return () => {
      clearInterval(repeatSequence);
    };
  }, []);

  return (
    <div className="section3-container">
      <img
        src={robot2}
        alt="Robot"
        className={`section3-image`}
      />
      <h2>
        Step 2. <span className="red-highlight">Replace Jobs </span>
      </h2>
      <p className="main-text">
        Get rid of the <span className="red-highlight">boring </span> ones
        first. Love your job? No problem! You can still work. It becomes
        optional. You have the <span className="red-highlight">freedom</span> to
        pursue your <span className="red-highlight">passions!</span>
      </p>

      <img src={dadImage} alt="Robot" className={"dad-image"} />

      <img src={LongSwr} alt="Robot" className={"long-swr"} />
      <img src={SolutionPic} alt="Robot" className={"solution-pic"} />
    </div>
  );
};

export default Section3;
