import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../stylesheets/Section2.css";
import KimRocket from "../assets/images/KimRocket.png";

import WeCan from "../assets/images/WECAN.png";
import WeMust from "../assets/images/WeMust.png";

import PleaseImage from "../assets/images/Please.png";



const Section2 = () => {
  const originalWord = "Accelerate";
  const [displayWord, setDisplayWord] = useState(originalWord);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.75, // Adjust the threshold here
  });

  useEffect(() => {
    let interval;
    if (inView) {
      let iterations = 0;
      const maxIterations = originalWord.length * 3; // Three iterations per letter

      interval = setInterval(() => {
        if (iterations >= maxIterations) {
          clearInterval(interval);
          return;
        }

        setDisplayWord((currentWord) => {
          return currentWord
            .split("")
            .map((char, index) => {
              if (index < Math.floor(iterations / 3))
                return originalWord[index];
              return String.fromCharCode(97 + Math.floor(Math.random() * 26));
            })
            .join("");
        });

        iterations++;
      }, 15); // Changes 10 times per second
    }

    return () => clearInterval(interval);
  }, [inView]);

  return (
    <div ref={ref} className="section2-container">
      <img src={WeMust} alt="Robot" className={"wemust-image"} />
      <img src={KimRocket} alt="Robot" className={"kim-rocket"} />

      <img src={WeCan} alt="Robot" className={"wecan-image"} />

      <img src={PleaseImage} alt="Robot" className={"please-image"} />
 
      <h2>
        Step 1. <span className="red-highlight">{displayWord}</span>
      </h2>
      <p className="sec2maintext">
        We <span className="red-highlight">need </span> to accelerate AI development
        as fast as possible. Every second we delay could mean a missed chance to
        lift people out of poverty; stalling is{" "}
        <span className="red-highlight">not</span> an option.
      </p>

      {/* Add more content here as needed */}
    </div>
  );
};

export default Section2;
