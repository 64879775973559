import React, { useState, useEffect } from "react";
import "../stylesheets/HeroSection.css";
import MascotImg from "../assets/images/Mascot.png";
import Einstein from "../assets/images/Enstein.png";
import GwsText from "../assets/images/gwstext.png";
import TrumpImage from "../assets/images/Trump.png";
import KimImage from "../assets/images/KimJonUn.png";
import RobotWorker from "../assets/images/robottiredofjob.png";
import FalcImage from "../assets/images/Falc.png";
import UbiImage from "../assets/images/UBI.png";
import LoadingAnimation from "../sections/LoadingAnimation";

function HeroSection() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const images = [
      MascotImg,
      Einstein,
      GwsText,
      TrumpImage,
      KimImage,
      RobotWorker,
      FalcImage,
      UbiImage
    ];

    let loadedCount = 0;

    images.forEach(src => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedCount++;
        if (loadedCount === images.length) {
          setLoaded(true);
        }
      };
    });
  }, []);

  if (!loaded) {
    return <LoadingAnimation />;
  }

  return (
    <div className="hero-section">
      <h1 className="hero-title">
        <span className="yellow">Grimes</span>
        <span className="black">Was</span>
        <span className="red">Right</span>
      </h1>
      <h2 className="hero-subtitle">Want your UBI? Scroll down.</h2>

      <img src={MascotImg} alt="Hero" className="hero-image" />
      <img src={Einstein} alt="Hero" className="einstein-image" />
      <img src={TrumpImage} alt="Hero" className="trump-image" />
      <img src={KimImage} alt="Hero" className="kim-image" />
      <img src={RobotWorker} alt="Hero" className="worker-image" />
      <img src={FalcImage} alt="Hero" className="falc-image" />
      <img src={UbiImage} alt="Hero" className="ubi-image" />
      <img src={GwsText} alt="Hero" className="gws-image" />
    </div>
  );
}

export default HeroSection;
