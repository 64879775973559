import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../stylesheets/ScrollSection.css";
import Section1 from "../sections/Section1.js";
import Section2 from "../sections/Section2.js";
import Section3 from "../sections/Section3.js";
import Section4 from "../sections/Section4.js";

function ScrollSection() {
  const sectionRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    // Ensure GSAP and ScrollTrigger are registered
    gsap.registerPlugin(ScrollTrigger);

    

    // Convert NodeList to Array using gsap.utils.toArray
    const slides = gsap.utils.toArray('.scroll-section', sectionRef.current);
    const correctedAmount = slides.length - 1;
    const delay = 0.1;
    const duration = 1;
    const totalDuration = delay + duration;
    const snapPoints = slides.map((_, index) => {
      return (delay + (duration / correctedAmount) * index) / totalDuration;
    });

    snapPoints.unshift(0); // Add 0 at the beginning of the array

    // Create a GSAP timeline with ScrollTrigger linked to it
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: triggerRef.current,
        start: 'bottom bottom',
        end: "4000 top",
        scrub: 0.65,
        pin: true,
        snap: {
          snapTo: snapPoints,
          duration: 0.3,

          delay: 0.1,
        }
      }
    });

    // Add animation to the timeline
    tl.fromTo(
      sectionRef.current,
      { translateX: 0 },
      { translateX: '-300vw', ease: 'none', duration: 1 },
      '+=0.1'
    );

    // Cleanup function to kill ScrollTrigger instances when the component unmounts
    return () => ScrollTrigger.getAll().forEach(st => st.kill());
  }, []);

  return (
    <section className="scroll-section-outer">
      <div ref={triggerRef} className="trigger">
        <div ref={sectionRef} className="scroll-section-inner">
          <div className="scroll-section">
            <Section1 />
          </div>
          <div className="scroll-section">
            <Section2 />
          </div>
          <div className="scroll-section">
            <Section3 />
          </div>
          <div className="scroll-section">
            <Section4 />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScrollSection;
