import React from "react";
import "../stylesheets/Section4.css";
import mayweather from "../assets/images/mayweather.gif";

import OkEmoji from "../assets/images/OkEmoji.png";

import PeaceSign from "../assets/images/PeaceSign.png";

const Section4 = () => {
  return (
    <div className="section4-container">
      <img src={mayweather} alt="Robot" className="section4-image" />

      <img src={OkEmoji} alt="Robot" className="ok-emoji" />
      <img src={PeaceSign} alt="Robot" className="peace-sign2" />
      <h2>
        Step 3. <span className="red-highlight">Redistribute the wealth</span>{" "}
      </h2>
      <p>
        Introduce a <span className="red-highlight">very</span> high tax on
        businesses and redistribute the wealth as
        <span className="red-highlight"> UBI</span>. The costs of goods will
        also plummet dramatically.
        <span className="red-highlight"> Welcome</span>{" "}
        <span className="red-highlight"> to</span>{" "}
        <span className="red-highlight"> luxury</span>{" "}
        <span className="red-highlight">communism!</span>
      </p>
      {/* Add more content here as needed */}
    </div>
  );
};

export default Section4;
