import React, { useState, useEffect } from "react";
import "../stylesheets/HeroSection.css";
import "../stylesheets/Section1.css";
import "../stylesheets/Section2.css";
import "../stylesheets/Section3.css";
import "../stylesheets/Section4.css";
import "../stylesheets/HomePage.css";
import Section1 from "../sections/Section1.js";
import Section2 from "../sections/Section2.js";
import Section3 from "../sections/Section3.js";
import Section4 from "../sections/Section4.js";
import Section5 from "../sections/Section5.js";
import Section6 from "../sections/Section6.js";
import Reveal from "../sections/Reveal.js";
import HeroSection from "../sections/HeroSection.js";
import ScrollSection from "../sections/ScrollSection.js";
import VideoSection from "../sections/VideoSection.js";


function HomePage() {
  const [fadeIn, setFadeIn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setFadeIn(true);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    handleResize(); // Check on initial load
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={`home-container ${fadeIn ? "fadeInAnimation" : ""}`}>
      <HeroSection />

      {isMobile ? (
        <>
          <Reveal threshold={0.35}>
            <Section1 />
          </Reveal>
          <Reveal threshold={0.25}>
            <Section2 />
          </Reveal>
          <Reveal threshold={0.25}>
            <Section3 />
          </Reveal>
          <Reveal threshold={0.25}>
            <Section4 />
          </Reveal>
        </>
      ) : (
        <ScrollSection />
      )}
      {/* Conditionally render VideoSection based on isMobile state */}
      {!isMobile && (
        <Reveal>
          <VideoSection />
        </Reveal>
      )}

      <Reveal>
        <Section5 />
      </Reveal>
      <Section6 />
    </div>
  );
}

export default HomePage;
