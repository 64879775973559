import React from "react";
import "../stylesheets/Section6.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faTiktok,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import towelie from "../assets/images/Towelie_29.png";
import nodoubt from "../assets/images/nodoubt.png";

const Section6 = () => {
  const handleNewsletterSignup = (event) => {
    event.preventDefault();
    // Add your logic to handle the newsletter signup here
    alert("Thank you for signing up for our newsletter!");
  };

  return (
    <div className="section6-container">

      <div className="content-wrapper">
        <div className="social-media">
          <a href="https://www.tiktok.com/@therevolutionarypod" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} className="icon" />
          </a>
          <a href="https://www.instagram.com/thefalcpod/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="icon" />
          </a>
          <a href="https://x.com/wewantubi" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTwitter} className="icon" />
          </a>
          <a href="https://www.youtube.com/@therevolutionarypod" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faYoutube} className="icon" />
          </a>

        </div>
        <img src={towelie} alt="Robot" className="towelie" />
        <img src={nodoubt} alt="Robot" className="nodoubt-poster" />
        <div className="newsletter-content">
          <p>Sign up to our newsletter for updates:</p>
          <form onSubmit={handleNewsletterSignup}>
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Section6;
