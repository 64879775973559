import React, { useState, useEffect, useRef } from "react";
import "../stylesheets/Section1.css";
import comment1 from "../assets/images/comment1trans.png";
import comment2 from "../assets/images/comment2trans.png";
import comment3 from "../assets/images/comment8trans.png";
import comment4 from "../assets/images/comment3trans.png";
import comment5 from "../assets/images/comment5trans.png";
import comment6 from "../assets/images/comment1trans.png";
import comment7 from "../assets/images/comment8trans.png";

import comment9 from "../assets/images/comment4trans.png";
import comment10 from "../assets/images/comment13trans.png";
import comment11 from "../assets/images/comment7trans.png";
import comment12 from "../assets/images/comment12trans.png";

import videoSource from "../assets/videos/Grimes_Discusses_Communism_Farming_and_A.I._online-video-cutter.com.mp4";
import videoThumbnail from "../assets/images/GrimesThumbnail3.jpg"; // Add your video thumbnail here
import DrawnSmiley from "../assets/images/drawnsmiley.png";
import NotJoke from "../assets/images/websitenojoke.png";
import NoSeriously from "../assets/images/nosrsly.png";


const commentImages = [
  { src: comment1, id: 1 },
  { src: comment2, id: 2 },
  { src: comment3, id: 3 },
  { src: comment4, id: 4 },
  { src: comment5, id: 5 },
  { src: comment6, id: 6 },
  { src: comment7, id: 7 },

  { src: comment9, id: 9 },
  { src: comment10, id: 10 },
  { src: comment11, id: 11 },
  { src: comment12, id: 12 },
];

function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
}

const shuffledImages = shuffleArray([...commentImages]); // Use a spread to clone and shuffle

const Section1 = () => {
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0); // eslint-disable-line no-unused-vars
  const [isVisible, setIsVisible] = useState(false); // eslint-disable-line no-unused-vars
  const sectionRef = useRef(null);
  const intervalIdRef = useRef(null); // useRef to keep track of the interval ID

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;

        if (entry.isIntersecting) {
          setIsVisible(true); // Start by showing the first image
          intervalIdRef.current = setInterval(() => {
            setIsVisible((prevIsVisible) => {
              if (prevIsVisible) {
                setTimeout(() => {
                  setCurrentVisibleIndex((prevIndex) => {
                    const nextIndex = (prevIndex + 1) % shuffledImages.length;
                    return nextIndex;
                  });
                  return false;
                }, 10);
              } else {
                return true;
              }
            });
          }, 1500); // Total interval for visibility toggle
        } else {
          clearInterval(intervalIdRef.current); // Clear interval when not intersecting
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.95,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      observer.disconnect();
      clearInterval(intervalIdRef.current); // Clean up on component unmount
    };
  }, []); // No dependencies to re-trigger this effect

  return (
    <div className="section1-container" ref={sectionRef}>
      <video className="sec1-video" controls poster={videoThumbnail}>
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
  
      <img src={DrawnSmiley} alt="Robot" className={"drawn-smiley"} />
      <img src={NotJoke} alt="Robot" className={"not-joke"} />
      <img src={NoSeriously} alt="Robot" className={"no-seriously"} />
      <p className="sec1-text">
        In 2021, <span className="red-highlight">Grimes</span> made a TikTok
        video named "AI is the fastest path to communism". She got a lot of
        hate, but was Grimes really that{" "}
        <span className="red-highlight">naive? </span>
      </p>
    </div>
  );
};

export default Section1;
