import React from "react";
import "../stylesheets/Section5.css";
import posterImage from "../assets/images/poster1.jpg";



const Section5 = () => {
  return (
    <div className="section5-container">
      <img
        src={posterImage}
        className="section5-image"
        alt="Descriptive Alt Text"
      />



      <div className="Mother-Review">
        <h2>“The mother of all revolutions!” </h2>
        <span className="red-highlight"><h2>-Times Magazine</h2></span>
      </div>

      <div className="Kim-Review">
        <h2>“A Spectacular Revolution!”</h2>
        <span className="red-highlight"><h2>-Kim Jong Un</h2></span>
        
      </div>
      <div className="reddit-review">
        <h2>“A Great Revolution! 6.9/10”</h2>
        <span className="red-highlight"><h2>-r/singularity</h2></span>
      </div>
      <div className="Trump-review">
        <h2>“Will there be sexbots?”</h2>
        <span className="red-highlight"><h2>-Donald Trump</h2></span>
      </div>
      <p className="concluding-text">
        Do you think grimes was right? Are you ready to realise the dream of
        fully automated luxury communism? Spread the word and stay connected.
        The revolution starts here.
      </p>
    </div>
  );
};

export default Section5;
