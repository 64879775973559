import React, { useEffect, useState, useRef } from "react";
import "../stylesheets/VideoSection.css";
import placeholderImage from "../assets/images/GrimesTube Transparent.png";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import ReactPlayer from "react-player";
import { IoPlaySkipBackSharp, IoPlaySkipForward } from "react-icons/io5";
import staticEffectVideo from "../assets/videos/StaticTransition.mp4";

import FreePalestineImg from "../assets/images/freepalestineimg.png";
import SmileyImage from "../assets/images/SmileyImage.png";

const VideoSection = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [showStatic, setShowStatic] = useState(false);
  const [showArrows, setShowArrows] = useState(false);
  const mouseMoveTimeout = useRef(null);

  useEffect(() => {
    const fetchVideoUrls = async () => {
      const storage = getStorage();
      const videosRef = ref(storage, "gs://grimeswasright.appspot.com");

      try {
        // Obtain download URLs for specific videos
        const secondVideoRef = ref(storage, "The Impact of AI on Jobs_ Debunking the Luxurious Lifestyle Theory.mp4");
        const firstVideoRef = ref(storage, "The Inevitable Rise of AI_ Solutions for Job Loss (1).mp4");

        const firstVideoUrl = await getDownloadURL(firstVideoRef);
        const secondVideoUrl = await getDownloadURL(secondVideoRef);

        // Fetch all video URLs
        let result = await listAll(videosRef);
        let urls = await Promise.all(
          result.items.map((itemRef) => getDownloadURL(itemRef))
        );

        // Filter out the first two videos from the list
        urls = urls.filter(url => url !== firstVideoUrl && url !== secondVideoUrl);

        // Shuffle the remaining videos
        urls = shuffleArray(urls);

        // Prepend the first two videos
        urls = [firstVideoUrl, secondVideoUrl, ...urls];

        setVideoUrls(urls);
        setCurrentIndex(0);
      } catch (error) {
        console.error("Error fetching video URLs: ", error);
      }
    };

    fetchVideoUrls();
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const changeVideo = (newIndex) => {
    setShowStatic(true);
    setTimeout(() => {
      setCurrentIndex(newIndex);
      setShowStatic(false);
    }, 400);
  };

  const goToNextVideo = () => {
    setUserHasInteracted(true);
    const newIndex = (currentIndex + 1) % videoUrls.length;
    changeVideo(newIndex);
  };

  const goToPreviousVideo = () => {
    setUserHasInteracted(true);
    const newIndex =
      currentIndex === 0 ? videoUrls.length - 1 : currentIndex - 1;
    changeVideo(newIndex);
  };

  const handleTouch = () => {
    setShowArrows(true);
    clearTimeout(mouseMoveTimeout.current);
    mouseMoveTimeout.current = setTimeout(() => setShowArrows(false), 2000);
  };

  const handleMouseMove = () => {
    setShowArrows(true);
    clearTimeout(mouseMoveTimeout.current);
    mouseMoveTimeout.current = setTimeout(() => setShowArrows(false), 2000);
  };

  return (
    <div className="video-section">
      <img
        src={placeholderImage}
        alt="Placeholder"
        className="video-section-image"
      />

      <img src={FreePalestineImg} alt="Robot" className="freepalestine-img" />
      <img src={SmileyImage} alt="Robot" className="smiley-img" />

      <div
        className="video-container"
        onMouseEnter={() => setShowArrows(true)}
        onMouseLeave={() => setShowArrows(false)}
        onTouchStart={handleTouch}
        onMouseMove={handleMouseMove}
      >
        {showStatic ? (
          <ReactPlayer
            url={staticEffectVideo}
            playing
            className="video-section-player"
            width="90vw"
            height="90vh"
          />
        ) : (
          videoUrls.length > 0 && (
            <ReactPlayer
              url={videoUrls[currentIndex]}
              playing={userHasInteracted}
              className="video-section-player"
              onEnded={goToNextVideo}
              controls
              width="90vw"
              height="90vh"
            />
          )
        )}
        <div
          className="BackArrow"
          style={{ opacity: showArrows ? 1 : 0 }}
          onClick={goToPreviousVideo}
        >
          <IoPlaySkipBackSharp />
        </div>
        <div
          className="ForwardArrow"
          style={{ opacity: showArrows ? 1 : 0 }}
          onClick={goToNextVideo}
        >
          <IoPlaySkipForward />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
